<template>
  <div class="flex mb-15 flex-align-c">
    <span class="flex2 title">
      文档（共{{pagination.total}}条）<span class="font-12">已选{{checkList.length}}</span>
    </span>
    <a-input-search
      class="flex1"
      :value="value"
      :placeholder="placeholder"
      enter-button
      @change="onChange"
      @search="pressEnter"
      :allowClear="allowClear"
    />
  </div>
  <a-table size="small" :dataSource="dataSource" :columns="columns" :rowSelection="rowSelection" rowKey="id" :pagination="pagination" @change="changePage">
    <template #file_name="{record}">
      <div class="flex flex-align-c">
        <a-image
          :preview="false"
          :width="25"
          :height="32"
          :src="getType(record)"
        />
        <span class="pl-10">{{record.file_name}}</span>
      </div>
    </template>
    <template #action1="{record}">
      <EyeOutlined class="icon-play" @click="hanldeReview(record)"/>
    </template>
  </a-table>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { EyeOutlined } from '@ant-design/icons-vue'
import { OSSURL } from '@/common/hooks/oss-config.js'
export default defineComponent({
  components: {
    EyeOutlined
  },
  props: {
    value: {}, // 搜索名字
    placeholder: {
      type: String,
      default: '请输入要搜索的文件名'
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    dataSource: {},
    pagination: {}
  },
  emits: ['change', 'pressEnter'],
  setup (props, ctx) {
    const videoUrl = ref()
    const visible = ref(false)
    const columns = [{
      title: '名称',
      dataIndex: 'file_name',
      slots: { customRender: 'file_name' },
      key: 'file_name'
    },
    {
      title: '操作',
      key: 'action',
      slots: { customRender: 'action1' },
      width: 50
    }]
    const onChange = (e) => {
      ctx.emit('update:value', e.target.value)
    }
    const hanldeReview = (record) => {
      if (record.type === 'w4') {
        // pdf跳转地址
        console.log('等待环境搭建')
        alert('等待环境搭建,先不管')
      } else {
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${OSSURL}/${record.file_path}`)
      }
    }
    // 记录已选列表
    const checkList = ref([])
    const rowSelection = reactive({
      selectedRowKeys: [], // 指定选中项的 key 数组
      onChange: (selectedRowKeys, selectedRows) => {
        rowSelection.selectedRowKeys = selectedRowKeys
        if (checkList.value.length === 0) {
          checkList.value = selectedRows
        } else {
          checkList.value = checkList.value.concat(selectedRows.filter((item) => !checkList.value.find((items) => items.id === item.id)))
        }
        checkList.value = checkList.value.filter((item) => selectedRowKeys.indexOf(item.id) !== -1)
      }
    })
    const getType = (record) => {
      switch (record.type) {
        case 'w1':
          return require('@/assets/images/icon_doc.png')
        case 'w2':
          return require('@/assets/images/icon_ppt.png')
        case 'w3':
          return require('@/assets/images/icon_xls.png')
        default:
          return require('@/assets/images/icon_ppt.png')
      }
    }
    const pressEnter = () => {
      ctx.emit('pressEnter')
    }
    const changePage = ({ current }) => {
      ctx.emit('change', current)
    }
    return {
      columns,
      hanldeReview,
      videoUrl,
      visible,
      rowSelection,
      onChange,
      getType,
      checkList,
      pressEnter,
      changePage
    }
  }
})
</script>

<style lang="scss" scoped>
.flex1{
  flex: 1;
}
.flex2{
  flex: 2;
}
.title{
  font-size: 15px;
  color: #000;
}
.icon-play{
  font-size: 20px;
  color: #999;
}
.font-12{
  font-size: 12px;
  color: #155FEF;
}
</style>
